import React, { useEffect, useRef, useState, useContext } from 'react'
import Swal from 'sweetalert2'
import { GlobalContext } from "../Globalstate";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import emailjs from "emailjs-com";
import DateTimeDisplay from '../Components/DateTimeDisplay';
import InvestDeposit from '../Components/InvestDeposit';
import Tradingview from '../Components/Tradingview';

function CompleteDeposit() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);


  const investmentPlans = [
    {
        title: 'Beginner Plan',
        minDeposit: 20.0,
        maxDeposit: 500.0,
        dailyProfit: 20, // Representing the 20% profit
        planDuration: 1, // 24 hours = 1 day
        referralBonus: 0, // If not provided, assuming no referral bonus
        profitReturn: 20, // Overall profit
        radio: 80
    },
    {
        title: 'Crypto-Pro',
        minDeposit: 1000.0,
        maxDeposit: 4000.0,
        dailyProfit: 35, // Representing the 35% profit
        planDuration: 2, // Duration in days
        referralBonus: 0, // If not provided, assuming no referral bonus
        profitReturn: 35, // Overall profit
        radio: 81
    },
    {
        title: 'FX-Pro',
        minDeposit: 5000.0,
        maxDeposit: 9000.0,
        dailyProfit: 50, // Representing the 50% profit
        planDuration: 4, // Duration in days
        referralBonus: 0, // If not provided, assuming no referral bonus
        profitReturn: 50, // Overall profit
        radio: 82
    },
    {
        title: 'Stocks-&-Pre-IPOs',
        minDeposit: 10000.0,
        maxDeposit: Infinity, // Representing unlimited max deposit
        dailyProfit: 75, // Representing the 75% profit
        planDuration: 7, // Duration in days
        referralBonus: 0, // If not provided, assuming no referral bonus
        profitReturn: 75, // Overall profit
        radio: 83
    },
];

  
//   const investmentPlans = [
//     {
//         title: 'STARTER PLAN',
//         minDeposit: 20.0,
//         maxDeposit: 500.0,
//         dailyProfit: 20,
//         referralBonus: 3,
//         planDuration: 4,
//         profitReturn: 10,
//         radio: 80
//     },
//     {
//         title: 'PREMIUM PLAN',
//         minDeposit: 2000.0,
//         maxDeposit: 4000.0,
//         dailyProfit: 30,
//         referralBonus: 5,
//         planDuration: 6,
//         profitReturn: 21,
//         radio: 81
//     },
//     {
//         title: 'GOLD PLAN',
//         minDeposit: 6000.0,
//         maxDeposit: 10000.0,
//         dailyProfit: 45,
//         referralBonus: 6,
//         planDuration: 8,
//         profitReturn: 40,
//         radio: 82
//     },
//     {
//         title: 'MINING PLAN',
//         minDeposit: 15000.0,
//         maxDeposit: 1000000.0,
//         dailyProfit: 75,
//         referralBonus: 10,
//         planDuration: 30,
//         profitReturn: 165,
//         radio: 83
//     },
// ];


  const ListOfCoins = [
    {
      name: "Bitcon",
      Symbol: "BTC",
      img: "../images/coins/btc.png",
      address: "bc1qr0y4kyz7uyj4pr6fqvdee98mng55y929a4lxnh"
    },
    {
      name: "Ethereum",
      Symbol: "ETH",
      img: "../images/coins/eth.png",
      address: "0x317AE592dfeAE86a385C6f9Be893ACA4df6C077C"
    },
    {
      name: "Litecoin",
      Symbol: "LTC",
      img: "../images/coins/ltc.png",
      address: "ltc1q2cdlk3kurmwqfhncjcnanrq4mfqw5mygdcxcz2"
    },
    {
      name: "USDT Erc20",
      Symbol: "USDT ERC20",
      img: "../images/coins/usdt.png",
      address: "0x317AE592dfeAE86a385C6f9Be893ACA4df6C077C"
    },
    {
      name: "Usdt",
      Symbol: "USDT Trc20",
      img: "../images/coins/usdt.png",
      address: "TPnSesLbJfBJdWFWUKAP53KmVciNG5ovsw"
    },

    {
      name: "WALLET",
      Symbol: "WALLET",
      img: "../images/coins/s-bal.png",
      address: "You will be paying from your wallet balance"
    },


  ]
  const [selectedplan, setselectedplan] = useState("")
  const [Selectedinvestment, setSelectedinvestment] = useState("")
  const [CoinInfo, setCoinInfo] = useState("")
  const [loading, setloading] = useState(false)
  const [investmentNmae, setinvestmentNmae] = useState("")
  const [Amount, setAmount] = useState(0)

  useEffect(() => {
    const url = new URL(global.window.location.href);
    const param1 = url.searchParams.get('Invetmentplan');
    setinvestmentNmae(param1)
    const param2 = url.searchParams.get('Coin');
    const param3 = url.searchParams.get('amount');
    console.log('param1:', param1);
    console.log('param2:', param2);
    console.log('param3:', param3);
    SetInvestment(param1)
    SetCoinInfo(param2)
    setAmount(param3)
  }, [])
  const SetInvestment = (value) => {
    setselectedplan(value)
    let selectedinvestment = investmentPlans.find(o => o.title === value);
    console.log(selectedinvestment)
    setSelectedinvestment(selectedinvestment)
  }
  const SetCoinInfo = (value) => {
    let selectedcoin = ListOfCoins.find(o => o.Symbol === value);
    console.log(selectedcoin)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `You Selected ${selectedcoin.name} as Payment Method`,
      showConfirmButton: false,
      timer: 1500
    })
    setCoinInfo(selectedcoin)
  }

  const [textToCopy, setTextToCopy] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = async (textto) => {
    try {
      await navigator.clipboard.writeText(textto);
      setIsCopied(true);
      handleUploadOfDeposit()
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const handleUploadOfDeposit = () => {
    if(CoinInfo.name==="WALLET"){
      Walletplanactivation()
      return
    }
    updatehistory();
    // setprogress(0);
    Swal.fire(
      'Succesful Transaction!',
      'Your account will be credited once the payment is recieved.',
      'success'
    )

  };



  const CreatUserObj = async (obj) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: obj
    };

    const userdetail1 = await db
      .collection("deposits")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
        Swal.fire(
          'Succesful Transaction!',
          'Your Investment will been activated.',
          'success'
        )
       // window.location.replace("/dashboard");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  const updatehistory = async () => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    CreatUserObj({
      // image: urls,
      date: Date.now(),
      amt: Amount,
      mode: "Deposit",
      coin: CoinInfo.name,
      investmentplan: selectedplan
    })
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      // Totaldeposit: increment,
      Deposithistory: firebase.firestore.FieldValue.arrayUnion({
        // image: urls,
        date: Date.now(),
        amt: Amount,
        mode: "Deposit",
        coin: CoinInfo.name,
        investmentplan: selectedplan
      }),
    });
  };




  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }

    let myDate = new Date();
    console.log(myDate.getTime())
    console.log(addHoursToDate(myDate, 1).getTime())
    console.log(myDate)
    console.log(addHoursToDate(myDate, 1))

  }, []);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .onSnapshot((function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setloading(false)
        } else {
          console.log("No such document!");
        }
      })
      )

  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };











  ///////////////fuction if user is paying from wallet
  const reffralCheck = () => {
    if (userdetails.referreduserid == "nnnnn") {
      console.log("np reffreal")
    } else {
      //increament referreduserid balance with 5% of invested amount
      var washingtonRef = db.collection("users").doc(userdetails.referreduserid);
      const increment = firebase.firestore.FieldValue.increment((5 * parseInt(Amount)) / 100);
      washingtonRef
        .update({
          balance: increment
        })
        .then(function () {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          var washingtonRef2 = db.collection("users").doc(userids);
          // set referreduserid to "nnnnn" 
          washingtonRef2
            .update({
              referreduserid: "nnnnn"
            })

        })
        .catch(function (error) {
          console.log("Error updating balance");

        });
    }
  }

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }
  const Walletplanactivation = () => {
    let SelectedInvestment = investmentPlans.find(o => o.title === investmentNmae);

    if (
      parseInt(userdetails.balance) !== 0 &&
      parseInt(Amount) <= parseInt(userdetails.balance)
    ) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Do you  want to invest in ${SelectedInvestment.title} From Your Wallet Balance`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue'
      }).then((result) => {
        if (result.isConfirmed) {
          let AMT1 = parseInt(Amount);
          // if (value == "1" && AMT1 >= 300 && AMT1 <= 10000) {
          // 92 no of days 1.8 is percent 
          let due = (parseFloat(SelectedInvestment.planDuration) * ((parseFloat(SelectedInvestment.dailyProfit) / 100) * AMT1)) + AMT1;
          let myDate = new Date();
          // multiply number of days with 24
          const d2 = addHoursToDate(myDate, (parseFloat(SelectedInvestment.planDuration) * 24)).getTime()
          var cap = new Date(d2);
          console.log(cap.toString());
          const subscription = {
            amount: AMT1,
            currentPlan: SelectedInvestment.title,
            dueAmount: due,
            dueDate: d2,
            dateSubscribed: new Date().getTime(),
          };
          updateUserBalanceAndSub(subscription, SelectedInvestment.title, due);
          Swal.fire(
            'Succesful Transaction!',
            'Your Investment has been activated.',
            'success'
          )
        }
      })

    } else {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'you dont have enough balance proceed to funding section to credit your account!',
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }
    // console.log(obj)




  };



  const updateUserBalanceAndSub = (subsription, plans, intrest) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(subsription.amount);
    washingtonRef
      .update({
        balance: parseFloat(userdetails.balance) - parseFloat(Amount),
        currentSubscription: subsription,
        Totaldeposit: increment,
        // Totaldeposit:increment,
        // totalearnings: increment,
        currentSubscriptionArray: firebase.firestore.FieldValue.arrayUnion(subsription),
        Investments: firebase.firestore.FieldValue.arrayUnion({
          date: Date.now(),
          plan: plans,
        }),
      })
      .then(function () {
        console.log("Document successfully updated!");
        CreatUserObj2(
          {
            date: Date.now(),
            plan: plans,
            amount: Amount
          },
          subsription,
          userids,
          userdetails.fullname

        )
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };

  const CreatUserObj2 = async (obj, subsription, userid, fullanme) => {
    let uobj = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      // fullname: userdetails.fullname,
      // email: userdetails.email,
      userid: userid,
      info: obj,
      subsription: subsription,
      fullname: fullanme

    };
    const userdetail1 = await db
      .collection("investments")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
        window.location.replace("/dashboard");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };


  return (
    <div>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title>Deposit  </title>
      <style dangerouslySetInnerHTML={{ __html: "\n      \n        .bottom-menu{\n            overflow: hidden;\n            position: fixed;\n            bottom: 0;\n            width: 100%;\n            background-color: #1b1b1c;\n            border-top: 1px solid #252526;\n            z-index:  1;\n        }\n        \n        .bottom-menu a{\n            width: 20%;\n            float: left;\n            display: block;\n            color: inherit;\n            text-align: center;\n            padding: 10px 12px;\n            text-decoration: none;\n            font-size: 10px;\n        }\n        \n        .bottom-menu .active{\n            color: #1eaae7;\n        }\n        \n        .bottom-menu a i{\n            font-size: 20px;\n            display: block;\n            margin-bottom: 3px;\n        }\n        \n        .bottom-menu a span{\n            \n        }\n        \n        \n        \n        .icon-menu-3 a{\n            width: 33.3%;\n            float: left;\n            display: block;\n            color: inherit;\n            text-align: center;\n            padding: 10px 12px;\n            text-decoration: none;\n            font-size: 10px;\n        }\n        \n        .icon-menu-3 .active{\n            color: #fb8c00;\n        }\n        \n        .icon-menu-3 a i{\n            font-size: 20px;\n            display: block;\n            margin-bottom: 3px;\n        }\n        \n        .login .wrapper .login-aside.bg-primary{\n            background-color: #fff !important;\n        }\n        \n        .login {\n    background: #fff;\n}\n        \n        .watsPanel{\n   z-index: 9;\n    position: fixed;\n    bottom: auto;\n    bottom: 0px;\n    left: 0px;\n    background-color: #fff;\n    border-radius: 0px 7px 0px 0px;\n    padding: 8px;\n    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);\n}\n\n.watsPanel a {\n    display: block;\n}\n\n.watsPanel a img{\n    max-height: 30px;\n    margin-bottom: 5px;\n}\n  " }} />
      <div className="wrapper">
        <Nav />
        <div id="loader" className="text-center card" style={{ position: 'fixed', display: 'none', left: 0, right: 0, top: 0, bottom: 0, zIndex: 9999, opacity: '0.855' }}>
          <div className="text-info text-center">
            <img className style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-40px', marginLeft: '-40px' }} src="../util/logo/logo-icon.png" height="auto" width="60px" />
          </div>
        </div>
        <div className="main-panel">
          <div className="container">
            <div className="bg-primary2 pt-4 pb-5" style={{ marginBottom: '-80px' }}>
              <div className="container text-white py-2">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h2 className="mt-1">Deposit</h2>
                  </div>
                  <div className="ml-auto">
                    <a href="Withdrawal" className="btn btn-warning btn-round btn-xs">Withdraw</a>
                    <a href="Transferfunds" className="btn btn-white btn-border btn-round btn-xs">Transfer</a>
                    <a href="transaction.php" className="btn btn-dark btn-round btn-xs">History</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-inner mt--5">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="card text-center">
                    <div className="card-body" id="divCancel">
                      <img src={CoinInfo.img} style={{ width: '80px', height: 'auto' }} className="img-lg rounded-circle mb-2" alt="" />
                      <h4>Deposit Successful</h4>
                      <ul className="list-unstyled plan-features myUl text-left">
                        <li>Transaction ID <b className>PAOS2905</b></li>
                        <li>Amount <b className>${Amount}</b></li>
                        <li>Transaction Fee <b className>$0.00</b></li>
                        <li>Date<b style={{}}><DateTimeDisplay /></b></li>
                        <li>Payment Method <b className><img src={CoinInfo.img} style={{ width: '20px', height: 'auto' }} /> {CoinInfo.name}</b></li>
                      </ul>
                      <small className="text-muted d-block">
                        Total Amount
                      </small>
                      <h3 className="card-title fw-bold">$ {Amount}</h3>
                      <small className="text-muted">
                        Pay the total amount to the {CoinInfo.name} wallet below.
                      </small>
                      <form className>
                        <div className="form-group">
                          <input type="text" id="ref_cop_1" className="form-control text-center" defaultValue={CoinInfo.address} />
                        </div>
                        <div className="form-group">
                          <button id="ref_btn_1" type="button" onClick={() => { handleCopyToClipboard(CoinInfo.address) }} className="btn btn-primary btn-block"><i className="fa fa-copy" aria-hidden="true" /> Click to proceed</button>
                          {isCopied && <p>Text copied to clipboard!</p>}
                        </div>
                      </form>
                      <p className="mt-2 text-muted"><small>
                        Send only {CoinInfo.name} to this address.
                        Sending any other coins may result in permanent loss.
                        <br />Your deposit will be cancelled if
                        you do not pay within 24 hours
                      </small>
                        <br />
                        <span className="text-warning fw-bold" id="countTime" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 <Tradingview/>
              <h4 className="page-title text-primary">Hot Pricing Plans<span className="float-right">
                <a href="plans.php" className="btn btn-primary btn-border btn-xs text-white">View All Plans</a>
              </span></h4>
              <InvestDeposit />
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              <nav className="pull-left">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="support.php">
                      Contact Support
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="ref.php">
                      My Referral
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright ml-auto">
                2023 made with <a href="index.php">Parexco</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="watsPanel">
        <a href="#langDiv">
          <img className="img-fluid mb-0" src="../images/icons/lang.png" alt="" />
        </a>
        <hr className="m-2" />
        <a href="http://wa.me/newell" id="heheh">
          <img className="img-fluid" src="../images/icons/whatsapp.png" alt="" />
        </a>
        <a href="http://wa.me/newell">
          <img className="img-fluid" src="../images/icons/whatsapp-2.png" alt="" />
        </a>
      </div>
      {/* jQuery UI */}
      {/* jQuery Scrollbar */}
      {/* Moment JS */}
      {/* Chart JS */}
      {/* jQuery Sparkline */}
      {/* Chart Circle */}
      {/* Datatables */}
      {/* Bootstrap Notify */}
      {/* Bootstrap Toggle */}
      {/* jQuery Vector Maps */}
      {/* Google Maps Plugin */}
      {/* Dropzone */}
      {/* Fullcalendar */}
      {/* DateTimePicker */}
      {/* Bootstrap Tagsinput */}
      {/* Bootstrap Wizard */}
      {/* jQuery Validation */}
      {/* Summernote */}
      {/* Select2 */}
      {/* Sweet Alert */}
      {/* Owl Carousel */}
      {/* Magnific Popup */}
      {/* Atlantis JS */}
      {/* Code provided by Google */}
      {/* End custom js for this page */}
    </div>
  )
}

export default CompleteDeposit